
const NotFoundPage = (props) => {

  //redirect page to privatefly.com domain with the same path if the page does not exist in gatsby
  if (typeof window !== 'undefined') {
    window.location.replace(`https://${window.location.host}${props.location.pathname}`);
  }

  return null;
}

export default NotFoundPage;
